<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            :comboItems="useFlagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="useFlag"
            label="사용여부"
            v-model="searchParam.useFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-table
          ref="equipTable"
          title="밀폐공간작업 장비 목록"
          tableId="equipTable"
          rowKey="heaClosedSpaceEquipmentId"
          :editable="editable"
          :isExcelDown="false"
          :columns="grid.columns"
          :data="grid.data"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn 
                v-if="editable && firstEditable" 
                :showLoading="false" 
                label="추가" 
                icon="add" 
                @btnClicked="addrow" />
              <c-btn
                v-if="editable && firstEditable && grid.data.length > 0"
                :url="saveUrl"
                :isSubmit="isSave"
                :param="grid.data"
                mappingType="PUT"
                label="저장"
                icon="save"
                @beforeAction="saveEquip"
                @btnCallback="saveCallback" />
              <c-btn label="검색" icon="search" @btnClicked="getList" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'cafeStock',
  data() {
    return {
      searchParam: {
        useFlag: 'Y',
        plantCd: '',
      },
      grid: {
        columns: [],
        data: [],
      },
      popupData: {
        first: '',
      },
      useFlagItems: [ // 사용여부
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      type: '',
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '60%',
        param: {},
        closeCallback: null,
      },
      isSave: false,
      saveUrl: '',
    };
  },
  computed: {
    firstEditable() {
      return this.editable;
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.hea.cse.equip.list.url;
      this.saveUrl = transactionConfig.hea.cse.equip.save.url;
      // code setting
      // list setting
      this.getList();
      this.setHeader();
    },
    setHeader() {
      this.$comm.getComboItems('HEA_CLOSED_EQUIP_UNIT_CD').then(_result1 => {
        this.grid.columns = [
          {
            required: true,
            name: 'plantCd',
            field: 'plantCd',
            label: '사업장',
            align: 'center',
            type: 'plant',
            style: 'width:180px',
            sortable: true,
          },
          {
            required: true,
            name: 'equipmentTypeCd',
            field: 'equipmentTypeCd',
            label: '장비종류',
            align: 'left',
            type: 'select',
            codeGroupCd: 'CLOSED_SPACE_EQUIPMENT_CD',
            style: 'width:180px',
            sortable: false,
          },
          {
            name: 'equipmentName',
            field: 'equipmentName',
            label: '장비명',
            type: 'text',
            align: 'left',
            sortable: true,
            style: 'width:200px',
            required: true,
          },
          {
            name: 'equipmentStockNumber',
            field: 'equipmentStockNumber',
            label: '창고 재고량',
            type: 'number',
            align: 'right',
            style: 'width:70px',
            sortable: true,
          },
          {
            name: 'unitCd',
            field: 'unitCd',
            label: '단위',
            align: 'center',
            style: 'width:70px',
            sortable: false,
            type: 'select',
            setHeader: true,
            comboItems: _result1
          },
          {
            name: 'useFlag',
            field: 'useFlag',
            label: '사용여부',
            align: 'center',
            style: 'width:70px',
            type: 'check',
            true: 'Y',
            false: 'N',
            sortable: false,
          },
        ]
      });
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
        this.popupData.first = null;
      },);
    },
    /* eslint-disable no-unused-vars */
    addrow() {
      this.grid.data.splice(0, 0, {
        heaClosedSpaceEquipmentId: '',
        equipmentName: '',
        equipmentStockNumber: '',
        useFlag: 'Y',
        editFlag: 'C',
        regUserId: this.$store.getters.user.userId
      })
    },
    saveEquip() {
      let flag = true;
      this.$_.forEach(this.grid.data, item => {
        if (!item.equipmentName) {
            window.getApp.$emit('ALERT', {
            title: '안내',
            message: '장비명을 입력하세요.',
            type: 'warning', // success / info / warning / error
          });
          flag = false;
        } else if (item.equipmentStockNumber < 0) {
          window.getApp.$emit('ALERT', {
            title: '안내',
            message: '재고량에 음수가 들어갈 수 없습니다.',
            type: 'warning', // success / info / warning / error
          });
          flag = false;
        }
      })
      if (flag) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.isSave = !this.isSave;
            this.chgUserId = this.$store.getters.user.userId
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
    },
  }
};
</script>
